import * as React from "react";
/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import styled from "@emotion/styled";
import {Badge, Button} from "antd";
import {ReactNode} from "react";

const Container = styled.div`
  padding: ${(props: any) => props.padding}px;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  position: relative;
`

const Left = styled.div`
`

const Title = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
`

const TitleAppend = styled.span`
  margin-left: 12px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const Desc = styled.div`
  margin-top: 8px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Options = styled.div`
`

const Buttons = styled.div`
  margin-left: 40px;
`

export type OptionCardProps = {
  title?: string | ReactNode;
  titleAppend?: string;
  desc?: string | ReactNode;
  padding?: number;
  options?: ReactNode;
  button?: string | boolean;
  onClick?: false | (() => any);
  required?: boolean;
}

const OptionCard = (props: OptionCardProps) => {
  const {
    title, titleAppend, desc, options, padding = 24, button = "编辑", onClick = false,
    required = false,
  } = props
  return (<>
    <Container {...{padding}}>
      {
        required && (
          <Badge
            status={"error"}
            // @ts-ignore
            css={css`
          display: block;
          position: absolute !important;
          top: 37px;
          left: 10px;
        `}
          />
        )
      }
      <Left>
        {title && (
          <Title>
            {title}
            <TitleAppend>{titleAppend}</TitleAppend>
          </Title>
        )}
        {desc && (<Desc>{desc}</Desc>)}
      </Left>
      <Right>
        {options && (
          <Options>{options}</Options>
        )}
        {button && onClick && (
          <Buttons>
            <Button {...{onClick}}>{button}</Button>
          </Buttons>
        )}
      </Right>
    </Container>
  </>)
}

export default OptionCard;
