import * as React from "react";
import styled from "@emotion/styled";

import {PlusCircleFilled} from '@ant-design/icons'

const Container = styled.div`
  height: 100px;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: #000000;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px dashed rgba(0,0,0,0.12);
  line-height: 100px;
  
  &:hover {
    padding: 1px;
    cursor: pointer;
    background: rgba(0,0,0,0.02);
    border: none;
  }
`

const PersonalAddCard = (props) => {
  const {
    text = '添加 ',
    ...containerProps
  } = props
  return (<>
    <Container {...containerProps}>
      <PlusCircleFilled /> {text}
    </Container>
  </>)
}

export default PersonalAddCard;
