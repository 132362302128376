import * as React from "react";
import PageWrapper from "../PageWrapper";
import styled from "@emotion/styled";
import { AppstoreOutlined, ControlOutlined, UserOutlined, WalletOutlined, FileDoneOutlined, SettingOutlined, } from "@ant-design/icons"
import {ReactNode} from "react";
import {Affix, message, Spin} from "antd";

const Container = styled.div`
  margin: 0 auto;
  width: ${(props: any) => props.width}px;
  display: flex;
  gap: 24px;
`

const Menu = styled.div`
  padding-top: 24px;
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const MenuItem = styled.a`
  padding-left: 24px;
  width: 148px;
  height: 46px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  & .anticon {
    color: rgba(0, 0, 0, 0.8);
  }
  &.selected, &:hover {
    color: rgb(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`

export type PersonalLayoutProps = {
  width?: number,
  loading?: boolean,
  selected?: 'workbench' | 'enterprise' | 'resume' | 'earning' | 'project' | 'setting'
  children?: ReactNode,
}


const PersonalLayout =  (props: PersonalLayoutProps) => {
  const {
    width = 1000,
    loading = false,
    selected = '',
    children,
  } = props
  return (<>
    <PageWrapper authorized>
      <Container {...{width}}>
        <Affix offsetTop={110}>
          <Menu>
            <MenuItem href='/personal/workbench' className={selected == "workbench" ? 'selected' : ''}><AppstoreOutlined/> 工作台</MenuItem>
            <MenuItem href='/personal/enterprise' className={selected == "enterprise" ? 'selected' : ''}><FileDoneOutlined/>企业信息</MenuItem>
            <MenuItem href='/personal/resume' className={selected == "resume" ? 'selected' : ''}><UserOutlined/>个人履历</MenuItem>
            <MenuItem href='/personal/earning' className={selected == "earning" ? 'selected' : ''}><WalletOutlined/>数字钱包</MenuItem>
            <MenuItem href='/personal/project' className={selected == "project" ? 'selected' : ''}><ControlOutlined />项目管理</MenuItem>
            <MenuItem href='/personal/setting' className={selected == "setting" ? 'selected' : ''}><SettingOutlined />系统设置</MenuItem>
          </Menu>
        </Affix>
        {loading && (
          <div style={{textAlign: 'center', margin: '0 auto'}}>
            <Spin/>
          </div>
        ) || children}
      </Container>
    </PageWrapper>
  </>)
}

export default PersonalLayout
