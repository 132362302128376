import * as React from "react";
import PersonalLayout from "../../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../../components/pages/personal/PersonalBody";
import {Button, message, Modal, Space} from "antd";
import OptionCard from "../../../../components/cards/optionCard";
import PersonalAddCard from "../../../../components/pages/personal/PersonalAddCard";
import Seo from "../../../../components/seo";
import {Link, navigate} from "gatsby";
import auth from "../../../../utils/auth";
import {useEffect, useState} from "react";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {useSelector} from "react-redux";
import * as Moment from "moment";

const PersonalCase = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const load = () => {
    if (loading) {
      return
    }
    jsonRequest("/account/case").then((rsp) => {
      if (rsp.success) {
        setData(rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => setLoading(false))
    setLoading(true)
  }

  const handleDestroy = (id: any) => {
    Modal.confirm({
      title: '确定删除',
      onOk: () => {
        return jsonRequest("/account/case", {
          method: 'DELETE',
          data: {id}
        }).then(rsp => {
          if (rsp.success) {
            message.success('删除成功')
            load()
          } else {
            message.error(rsp.message)
          }
        }).catch(console.log)
      }
    })
  }

  useEffect(() => {
    if (!!account) {
      load()
    }
  }, [account])

  return (<>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody
        title='项目案例'
        backLink='/personal/resume'
      >
        <Space size={16} direction='vertical' style={{width: '100%'}}>
          {data?.map(item => (
            <OptionCard
              title={item.name}
              desc={`更新日期：${Moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss")}`}
              key={item.id}
              options={(
                <Space size={8}>
                  <Link
                    to={`/personal/resume/case/edit?id=${item.id}`}
                  >
                    <Button>编辑</Button>
                  </Link>
                  <Button type='primary' onClick={() => handleDestroy(item.id)} danger>删除</Button>
                </Space>
              )}
            />
          ))}
          <PersonalAddCard text='添加案例' onClick={() => navigate('/personal/resume/case/add')}/>
        </Space>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalCase;

export const Head = () => <Seo />
